export const SurgeonCustomerSelectorLaunchSources = Object.freeze({
    LOGIN: 'LOGIN',
    DASHBOARD: 'DASHBOARD',
});

export const SortCategories = Object.freeze({
    ACTIVITY: 'updated',
    CUSTOMER: 'name',
    ID: 'ocosCustomerNumber',
    COUNTRY: 'country',
});
/**
 * Customer address types
 */
export const AddressTypes = Object.freeze({
    SHIPPING: 1,
    BILLING: 2,
    TEMP_SHIPPING: 3,
});
