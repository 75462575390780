import router from '@/router';
import {uniq} from 'lodash';

/**
 * Get all the route names related to the checkout process
 *
 * @param {Array} routes - array of routes to search
 */
export function getCheckoutRouteNames(routes = router.options.routes) {
    // Add the children checkout route names
    let checkoutRouteNames = routes
        .find((r) => r.name === 'ShoppingCartWrapper')
        .children.find((c) => c.name === 'Checkout')
        .children.map((c) => c.name);

    // Dedupe the route names
    checkoutRouteNames = uniq(checkoutRouteNames);

    return checkoutRouteNames;
}
