// current user module
import mergeWith from 'lodash/mergeWith';
import {DEFAULT_DECIMAL_SEPATOR, DEFAULT_LANGUAGE_KEY} from '@/config';
import {AcknowledgementLocalStorageKey} from '@/constants/user';
import {ZoneCodes} from '@/constants/zone';

const state = () => ({
    currentUser: null,
    myZones: null,
    myLanguages: [],
    activeCustomer: null,
    defaultCustomer: null,
    activeDoctor: null,
    defaultDoctor: null,
    myAcknowledgement: localStorage.getItem(AcknowledgementLocalStorageKey),
});

const getters = {
    currentUser: ({currentUser}, _, __, rootGetters) => {
        const currentZone = rootGetters['zone/currentZone'];
        const enforceUserSettings = {language: currentUser?.language ?? DEFAULT_LANGUAGE_KEY};
        // When US zone, users should use fixed settings per https://gitlab.artlogic.com/StaarSurgical/Orion/-/issues/2623
        if (!currentZone || currentZone == ZoneCodes.US) {
            enforceUserSettings.decimalSeparator = DEFAULT_DECIMAL_SEPATOR;
            enforceUserSettings.language = DEFAULT_LANGUAGE_KEY;
        }
        const user = currentUser
            ? mergeWith(
                  {decimalSeparator: DEFAULT_DECIMAL_SEPATOR},
                  currentUser,
                  enforceUserSettings,
                  (a, b) => (b === null ? a : undefined)
              )
            : currentUser;
        return user;
    },
    /**
     * The current user's available zones
     *
     * @param {Object} state
     * @returns the zones
     */
    myZones: (state) => state.myZones,
    myLanguages: (state) =>
        state.myLanguages.map((l) => ({...l, name: `${l.name} (${l.code.toUpperCase()})`})),
    /**
     * The current active customer object
     *
     * @param {Object} state
     * @returns the current active customer
     */
    activeCustomer: (state) => state.activeCustomer,
    /**
     * The current default customer object
     *
     * @param {Object} state
     * @returns the current default customer
     */
    defaultCustomer: (state) => state.defaultCustomer,
    /**
     * The current active doctor object
     *
     * @param {Object} state
     * @returns the current active doctor
     */
    activeDoctor: (state) => state.activeDoctor,
    /**
     * The current default doctor object
     *
     * @param {Object} state
     * @returns the current default doctor
     */
    defaultDoctor: (state) => state.defaultDoctor,
    /**
     * @deprecated Should not be on the user model. Should be stored in local
     *   storage. Will be removed from the user model for the multiple tabs
     *   feature.
     */
    activeCustomerId: (state) => state.currentUser?.activeCustomerId,
    /**
     * @deprecated Should not be on the user model. Should be stored in local
     *   storage. Will be removed from the user model for the multiple tabs
     *   feature.
     */
    activeDoctorId: (state) => state.currentUser?.activeDoctorId,
    /**
     * The default customer id as selected by the user in the
     * Surgeon/Customer Selector modal
     *
     * @param {Object} state
     * @returns the customer id
     */
    defaultCustomerId: (state) => state.currentUser?.defaultCustomerId,
    /**
     * The default doctor id as selected by the user in the
     * Surgeon/Customer Selector modal
     *
     * @param {Object} state
     * @returns the doctor id
     */
    defaultDoctorId: (state) => state.currentUser?.defaultDoctorId,
    userThumbnailUrl: (state) => {
        if (state.currentUser && state.currentUser.thumbnailUrl) {
            return state.currentUser.thumbnailUrl;
        }
        //return the blank user image if no URL
        return require('../../assets/blankuser.png');
    },
    /**
     * Indicates whether or not the current user has acknowledged the message
     * presented during login
     *
     * @param {Object} state
     * @returns the acknowledgement status
     */
    myAcknowledgement: (state) => state.myAcknowledgement,
};

const mutations = {
    setProfile(state, currentUser) {
        state.currentUser = currentUser;
    },
    setMyZones(state, myZones) {
        state.myZones = myZones;
    },
    setMyLanguages(state, myLanguages) {
        state.myLanguages = myLanguages;
    },
    setActiveCustomer(state, activeCustomer) {
        state.activeCustomer = activeCustomer || null;
    },
    setDefaultCustomer(state, defaultCustomer) {
        state.defaultCustomer = defaultCustomer || null;
    },
    setActiveDoctor(state, activeDoctor) {
        state.activeDoctor = activeDoctor || null;
    },
    setDefaultDoctor(state, defaultDoctor) {
        state.defaultDoctor = defaultDoctor || null;
    },
    setGdprAcceptance(state, {gdprAcceptanceDate, gdprMessageCount}) {
        state.currentUser['gdprAcceptanceDate'] = gdprAcceptanceDate;
        state.currentUser['gdprMessageCount'] = gdprMessageCount;
    },
    setMyAcknowledgement(state, myAcknowledgement) {
        localStorage.setItem(AcknowledgementLocalStorageKey, myAcknowledgement);
        state.myAcknowledgement = myAcknowledgement;
    },
    removeMyAcknowledgement(state) {
        localStorage.removeItem(AcknowledgementLocalStorageKey);
        state.myAcknowledgement = null;
    },
};

const actions = {
    async fetchUser({commit}) {
        const user = (await this._vm.$http.get(`users/me`)).data;

        //append the zone info to the current user object
        const zoneInfo = (await this._vm.$http.get(`users/me/zones`)).data;
        user.zoneInfo = zoneInfo;

        commit('setProfile', user);
    },

    /* Fetch the current user's available zones */
    async fetchMyZones({commit}) {
        let urlString = `users/me/zones`;
        try {
            let result = await this._vm.$http.get(urlString);
            let myZones = result.data;
            commit('setMyZones', myZones);
        } catch (err) {
            alert("Unable to fetch the current user's available zones.");
        }
    },

    /* Fetch the current user's languages */
    async fetchMyLanguages({commit}) {
        const urlString = `users/languages`;
        try {
            const {data} = await this._vm.$http.get(urlString);
            const myLanguages = data.results;
            commit('setMyLanguages', myLanguages);
        } catch (err) {
            alert("Unable to fetch the current user's languages.");
        }
    },

    /* Fetch the current user's active customer, if any */
    async fetchActiveCustomer({commit}) {
        let urlString = 'users/me/activecustomer';
        try {
            const {data: activeCustomer} = await this._vm.$http.get(urlString);
            commit('setActiveCustomer', activeCustomer);
        } catch (err) {
            alert('Unable to fetch active customer');
        }
    },

    /* Fetch the current user's default customer, if any */
    async fetchDefaultCustomer({commit}) {
        let urlString = 'users/me/defaultcustomer';
        try {
            const {data: defaultCustomer} = await this._vm.$http.get(urlString);
            commit('setDefaultCustomer', defaultCustomer);
        } catch (err) {
            alert('Unable to fetch default customer');
        }
    },

    /* Fetch the current user's active doctor, if any */
    async fetchActiveDoctor({commit}) {
        let urlString = 'users/me/activedoctor';
        try {
            const {data: activeDoctor} = await this._vm.$http.get(urlString);
            commit('setActiveDoctor', activeDoctor);
        } catch (err) {
            alert('Unable to fetch active doctor');
        }
    },

    /* Fetch the current user's default doctor, if any */
    async fetchDefaultDoctor({commit}) {
        let urlString = 'users/me/defaultdoctor';
        try {
            const {data: defaultDoctor} = await this._vm.$http.get(urlString);
            commit('setDefaultDoctor', defaultDoctor);
        } catch (err) {
            alert('Unable to fetch default doctor');
        }
    },

    /* Store the active customer for the current user */
    async setActiveCustomerId({commit, getters, dispatch}, customerId) {
        let urlString = 'users/me/activeCustomer';
        try {
            const {data: activeCustomer} = await this._vm.$http.post(urlString, {
                customerId: customerId ? parseInt(customerId) : customerId,
            });
            commit('setActiveCustomer', activeCustomer);
            this._vm.bc.postMessage(customerId); // Broadcast to others browser tabs the customer has changed.
            // Some user info is based on the customer being viewed, so
            // re-fetch the user. This also updates the user's active customer.
            await dispatch('fetchUser');
        } catch (err) {
            alert('Unable to set active customer');
        }
    },

    /* Store the active doctor (surgeon) for the current user */
    async setActiveDoctorId({commit, dispatch}, doctorId) {
        let urlString = 'users/me/activeDoctor';
        try {
            const {data: activeDoctor} = await this._vm.$http.post(urlString, {
                doctorId: doctorId ? parseInt(doctorId) : doctorId,
            });
            commit('setActiveDoctor', activeDoctor);
            // Re-fetch the user so that the active customer id will be updated.
            await dispatch('fetchUser');
        } catch (err) {
            alert('Unable to set active doctor');
        }
    },

    /* Store the default customer for the current user */
    async setDefaultCustomerId({commit, dispatch}, customerId) {
        let urlString = 'users/me/defaultCustomer';
        try {
            const {data: defaultCustomer} = await this._vm.$http.post(urlString, {
                customerId: customerId ? parseInt(customerId) : customerId,
            });
            commit('setDefaultCustomer', defaultCustomer);
            // Re-fetch the user so that the default customer id will be updated.
            await dispatch('fetchUser');
        } catch (err) {
            alert('Unable to set default customer');
        }
    },

    /* Store the default doctor (surgeon) for the current user */
    async setDefaultDoctorId({commit, dispatch}, doctorId) {
        let urlString = 'users/me/defaultDoctor';
        try {
            const {data: defaultDoctor} = await this._vm.$http.post(urlString, {
                doctorId: doctorId ? parseInt(doctorId) : doctorId,
            });
            commit('setDefaultDoctor', defaultDoctor);
            // Re-fetch the user so that the default doctor id will be updated.
            await dispatch('fetchUser');
        } catch (err) {
            alert('Unable to set default doctor');
        }
    },

    /* Set GDPR acceptance for the current user */
    async setGdprAcceptance({commit}) {
        let urlString = 'users/me/gdpracceptance';
        try {
            const {data} = await this._vm.$http.post(urlString);
            commit('setGdprAcceptance', data.results);
            return data.success;
        } catch (err) {
            alert('Unable to set GDPR acceptance');
        }
    },

    /* Fetch the current user's forced logout status */
    async fetchMyForcedLogoutStatus({commit, state}) {
        const urlString = `users/me/forcedlogoutstatus`;
        try {
            const forcedLogoutStatus = (await this._vm.$http.get(urlString)).data;
            commit('setProfile', {...state.currentUser, forcedLogoutStatus});
        } catch (err) {
            // When the user's zone is changed this request will return 401 because
            // the zone on the backend will be different from the zone stored on
            // the frontend. In this case, force the user to logout.
            if (err.response?.status === 401) {
                commit('setProfile', {...state.currentUser, forcedLogoutStatus: true});
            }
        }
    },

    /* Set the current user's forced logout status */
    async setMyForcedLogoutStatus({commit, state}, forcedLogoutStatus) {
        const urlString = `users/me/forcedlogoutstatus`;
        await this._vm.$http.post(urlString, {forcedLogoutStatus});
        commit('setProfile', {...state.currentUser, forcedLogoutStatus});
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
