<template>
    <div :class="['overlay', `overlay-${position}`]" id="spinner-overlay">
        <div class="spinner-container">
            <b-spinner variant="primary-dark" class="loading-spinner"></b-spinner>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SpinnerOverlay',
    props: {
        position: {
            default: 'fixed',
        },
    },
};
</script>

<style lang="scss" scoped>
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1041;
}
.overlay-fixed {
    position: fixed;
}
.overlay-absolute {
    position: absolute;
}
.loading-spinner {
    width: 4rem;
    height: 4rem;
}
</style>
