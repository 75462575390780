import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import Backend from 'i18next-http-backend';
import get from 'lodash/get';

// https://www.i18next.com/overview/getting-started
export const initialize = function (Vue, store, launchConfig = null) {
    i18next.on('languageChanged', (locale) => {
        if (locale) {
            // return store.dispatch('context/updateLocale', locale);
        }
        return;
    });

    const option = {
        debug: false,
        fallbackLng: 'en',
        saveMissing: false,
        ns: ['translation'],
        defaultNS: 'translation',
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },
    };

    // Set wanted locale
    const targetLocale = get(launchConfig, 'locale', null);
    if (targetLocale) {
        option.lng = targetLocale;
    }

    return i18next
        .use(Backend)
        .init(option)
        .then(() => {
            Vue.use(I18NextVue, {
                i18next,
                rerenderOn: ['initialized', 'languageChanged', 'loaded'],
            });
            // Extend the Vue prototype with a custom translation function (e.g., t)
            Vue.prototype.t = function (key, options) {
                return i18next.t(key, options);
            };
            return i18next.resolvedLanguage;
        });
};

export default {
    i18next,
};
