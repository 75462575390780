import {CURRENCY_FORMAT, LOCALE_CURRENCY_FORMAT} from '@/config';
import store from '@/store';

export default function (
    value,
    {minimumFractionDigits = 2, locale = LOCALE_CURRENCY_FORMAT, currency = CURRENCY_FORMAT} = {}
) {
    if (typeof value !== 'number') {
        return value;
    }
    const {currencyLocale, isoCurrencyCode} = store.getters['user/activeCustomer'] || {};
    locale = currencyLocale || locale;
    currency = isoCurrencyCode || currency;
    let formatter;
    try {
        /* There is a slight chance that the backend does not use the appropriate 
            locale for the user in question. */
        formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            useGrouping: true,
            currency,
            minimumFractionDigits,
        });
    } catch (error) {
        console.error(
            `Locale '${locale}' or currency ${currency} is not supported, ${LOCALE_CURRENCY_FORMAT} and ${CURRENCY_FORMAT} were used as default.`
        );
        console.error(error);
        /* We must handle the possibility that the locale used is not appropriate and, 
            in that case, return the currency formatted with default settings. */
        formatter = new Intl.NumberFormat(LOCALE_CURRENCY_FORMAT, {
            style: 'currency',
            useGrouping: true,
            currency: CURRENCY_FORMAT,
            minimumFractionDigits,
        });
    }
    return formatter.format(value);
}
