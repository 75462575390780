<template>
    <b-modal
        id="gdpr"
        centered
        no-fade
        hide-backdrop
        no-close-on-backdrop
        no-close-on-esc
        visible
        size="md"
        content-class="gdpr-content"
        header-class="gdpr-header"
        body-class="gdpr-body"
        footer-class="gdpr-footer"
    >
        <template #modal-header> {{ t(type) }} </template>
        <template #default>
            <b-container>
                <b-row>
                    <b-col>
                        <transition name="fade">
                            <p
                                :key="gdprMessageCount"
                                v-html="t(`${type}_Message_${currentZone}_${gdprMessageCount + 1}`)"
                            ></p>
                        </transition>
                    </b-col>
                </b-row>
            </b-container>
        </template>
        <template #modal-footer>
            <b-container>
                <b-row>
                    <b-col>
                        <b-button block variant="secondary" @click="onAccept">
                            {{ t('capsYes') }}
                        </b-button>
                        <b-button block variant="secondary" @click="onDenied">
                            {{ t('capsNo') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </template>
    </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import {ZoneCodes} from '@/constants/zone';

/**
 * Forces the user to accept the displayed message.
 *
 * @emits on-accepted Emitted when the user accept the message.
 */
export default {
    name: 'GdprModal',
    components: {},
    props: {
        showGdprModal: {
            type: Boolean,
            default: true,
        },
        gdprMessageCount: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            ZoneCodes,
        };
    },
    computed: {
        ...mapGetters('zone', ['currentZone']),
        type() {
            return this.currentZone == ZoneCodes.US ? 'pnmc' : 'gdpr';
        },
    },
    methods: {
        /**
         * Handle the acceptance
         */
        onAccept() {
            // US only have one message to show, if accept process is completed
            // OUS have two messages to show, we need to ensure we're in the second want by condition gdprMessageCount == 1
            const isCompleted = this.currentZone == ZoneCodes.US || this.gdprMessageCount == 1;
            this.$emit('on-accepted', isCompleted);
            this.$emit('update:showGdprModal', !isCompleted);
        },
        /**
         * Handle the denial
         */
        onDenied() {
            this.$emit('on-denied');
            this.$emit('update:showGdprModal', false);
        },
    },
};
</script>

<style lang="scss">
@import '../assets/css/variables';
@import '../assets/css/mixins';

#gdpr {
    .text-black.heavy,
    .text-gray-darker.heavy {
        @include heavy;
    }

    .btn-secondary {
        color: #616469;
        background-color: $white;
        border-color: $gray-dark;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .gdpr {
        &-content {
            box-shadow: 0px 12px 30px rgb(0 0 0 / 24%);
        }

        &-header {
            padding-left: 2rem;
            background-color: $primary-dark;
            font-size: 2rem;
            color: $white;
        }

        &-body {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
            padding-bottom: 0rem;
        }

        &-footer {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0rem;
            padding-bottom: 1rem;
        }
    }
}

.fade-enter-active {
    transition: opacity 0.5s;
}
.fade-leave-active {
    transition: opacity 0s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
