<template>
    <div
        v-if="searchedItem[`${searchedProperty}${SearchResultProperties.TEXT_FOUND}`]"
        class="searched-text"
    >
        <!-- Preserve this HTML structure to ensure whitespace is handled correctly -->
        <!----->
        <div :class="[baseTextClass, disabledClass]">
            <span>{{
                searchedItem[`${searchedProperty}${SearchResultProperties.TEXT_FOUND_PREFIX}`]
            }}</span>
        </div>
        <!----->
        <div :class="[foundTextClass, disabledClass]">
            <span>{{
                searchedItem[`${searchedProperty}${SearchResultProperties.TEXT_FOUND}`]
            }}</span>
        </div>
        <!----->
        <div :class="[baseTextClass, disabledClass]">
            <span>{{
                searchedItem[`${searchedProperty}${SearchResultProperties.TEXT_FOUND_SUFFIX}`]
            }}</span>
        </div>
    </div>
    <div v-else class="searched-text">
        <div :class="[baseTextClass, disabledClass]">
            <span>{{ searchedItem[searchedProperty] }}</span>
        </div>
    </div>
</template>

<script>
import {SearchResultProperties} from '@/constants/search';

/**
 * This component handles the display of searched text and is initially used
 * with the setSearchResultProperties function. If the text was found it will
 * appear emboldened. If disabled is set, the text will appear disabled.
 */
export default {
    name: 'SearchedText',
    components: {},
    props: {
        /**
         * Determines if the displayed text should appear disabled
         */
        disabled: {
            type: Boolean,
            required: false,
        },
        /**
         * Contains all the data needed to display the search result
         *   ${searchedProperty}}
         *   ${searchedProperty}${SearchResultProperties.TEXT_FOUND_PREFIX}
         *   ${searchedProperty}${SearchResultProperties.TEXT_FOUND}
         *   ${searchedProperty}${SearchResultProperties.TEXT_FOUND_SUFFIX}
         */
        searchedItem: {
            type: Object,
            required: true,
        },
        /**
         * The name of the searched object property
         */
        searchedProperty: {
            type: String,
            required: true,
        },
        /**
         * The base text class
         */
        classBaseText: {
            type: String,
            required: false,
        },
        /**
         * The found text class
         */
        classFoundText: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            SearchResultProperties,
        };
    },
    watch: {
        $props: {
            immediate: true,
            handler() {
                this.validateProps();
            },
        },
    },
    computed: {
        /**
         * The class for disabled text
         */
        disabledClass: function () {
            return this.disabled ? 'text-disabled' : '';
        },
        /**
         * The base text class i.e. the class used for the text that didn't
         * match the search text.
         */
        baseTextClass: function () {
            return this.classBaseText || 'text-base';
        },
        /**
         * The class for the text that matched the search text
         */
        foundTextClass: function () {
            return this.classFoundText || 'text-found';
        },
    },
    methods: {
        /**
         * Validate properties that depend on other properties. All other
         * valdation is done in the props section.
         */
        validateProps: function () {
            let error = '';
            Object.values(SearchResultProperties).forEach((searchResultProperty) => {
                let property = `${this.searchedProperty}${searchResultProperty}`;
                if (!Object.keys(this.searchedItem).includes(property)) {
                    error += `The search item does not have the property ${property}\n`;
                    throw Error(error);
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import '../assets/css/variables';
@import '../assets/css/typography';

.searched-text {
    display: flex;
    align-items: center;
    white-space: pre;

    .text-base {
        @extend .text-gray-darker;

        &.text-disabled {
            @extend .text-gray-dark;
        }
    }

    .text-found {
        @extend .text-black;
        @include heavy;

        &.text-disabled {
            @extend .text-gray-dark;
        }
    }
}
</style>
