export const PERMISSIONS = Object.freeze({
    PATIENT_PREOP_DATA: 'PatientPreOpData',
    PATIENT_PERSONAL_DATA: 'PatientPersonalData',
    PATIENT_CALCULATIONS: 'PatientCalculations',
    PATIENT_CALCULATIONS_APPROVAL: 'PatientCalculationsApproval',
    CREATE_PATIENT: 'CreatePatient',
    INVENTORY_LENS_SELECTION: 'InventoryLensSelection',
    ORDERING: 'Ordering',
    RESERVATIONS: 'Reservations',
    ORDERS: 'Orders',
    SURGEONS: 'Surgeons',
    USERS: 'Users',
    CUSTOMER_ADMIN: 'CustomerAdmin',
    DISTRIBUTOR_MODE: 'DistributorMode',
    IOD_OVERRIDE: 'IodOverride',
});

export const PERMISSIONS_VALUES = Object.freeze({
    NA: 'NA',
    LIMITED_READ_ONLY: 'LimitedReadOnly',
    LIMITED_READ_WRITE: 'LimitedReadWrite',
    READ_ONLY: 'ReadOnly',
    READ_WRITE: 'ReadWrite',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    SURGEON: 'Surgeon',
    DISTRIBUTOR: 'Distributor',

    READ_TYPES: ['LimitedReadOnly', 'LimitedReadWrite', 'ReadOnly', 'ReadWrite'],
    WRITE_TYPES: ['LimitedReadWrite', 'ReadWrite'],
});
