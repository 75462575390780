import snakeCase from 'lodash/snakeCase';

const state = () => ({
    patientList: null,

    /* Page details for the table of the patient list.
    - The total number of patients/rows  (pageDetails.total)
    - The total number of pages (pageDetails.totalPages)
  */
    pageDetails: {
        total: 0,
        totalPages: 0,
    },
});

const getters = {
    patientList: (state) => state.patientList,
    pageDetails: (state) => state.pageDetails,
};

const mutations = {
    setPatientList(state, list) {
        state.patientList = list;
    },

    emptyPatientList(state) {
        if (state.patientList) state.patientList.splice(0, state.patientList.length);
    },

    setPageDetails(state, details) {
        state.pageDetails = details;
    },
    // use an object to set the page & patient list
    setPatientInfo(state, info) {
        if (info) {
            state.patientList = info.list;
            state.pageDetails = {total: info.total, totalPages: info.totalPages};
        } else {
            state.patientList = null;
            state.pageDetails = null;
        }
    },
};

const actions = {
    /* Fetch the list of patients with the specified parameters. */
    async fetchPatientList({commit}, queryParams) {
        // Casting properties name to snakeCase
        queryParams = Object.keys(queryParams).reduce(
            (acc, key) => ({...acc, [snakeCase(key)]: queryParams[key]}),
            {}
        );
        const params = new URLSearchParams(queryParams).toString();

        const response = await this._vm.$http.get(`patients?${params}`);

        if (response.status == 200) {
            const info = {
                list: response.data.results,
                total: response.headers['x-orion-total'],
                totalPages: response.headers['x-orion-totalpages'],
            };
            commit('setPatientInfo', info);
        } else {
            console.log('Invalid response when fetching patient list.');
            commit('setPatientInfo', null);
        }
    },

    clearPatientList({commit}) {
        commit('emptyPatientList');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
