<template>
    <div>
        <b-toast
            :id="'toast-error-' + ident"
            variant="danger"
            :no-auto-hide="!autoHide"
            no-close-button
            :visible="visible"
            solid
        >
            <div class="d-flex align-items-start">
                <b-icon-exclamation-octagon-fill class="mr-3" font-scale="2.5" />
                <div>
                    <h5 class="heavy mb-1">{{ title }}</h5>
                    <slot> {{ msg ? msg : t('toastTitle_ErrorOccured') }} </slot>
                </div>
            </div>
        </b-toast>
    </div>
</template>

<script>
export default {
    name: 'ErrorToast',

    props: {
        ident: String,

        visible: {
            type: Boolean,
            default: false,
        },

        autoHide: {
            type: Boolean,
            default: false,
        },

        title: {
            type: String,
            default: 'ERROR',
        },

        msg: {
            type: String,
            default: '',
        },
    },
};
</script>
