// This module fetches the backend API version info from the relevant endpoint.  This info will then be printed out to the console.
import axios from 'axios';
import {CALCULATOR_API} from '@/config';

//
// STATE
//
const state = () => ({
    apiVersionInfo: null,
    calculatorApiVersionInfo: null,
});

//
// GETTERS
//
const getters = {
    apiVersionInfo: (state) => state.apiVersionInfo,
    calculatorApiVersionInfo: (state) => state.apiVersionInfo,
};

//
// MUTATIONS
//
const mutations = {
    setApiVersionInfo(state, version) {
        state.apiVersionInfo = version;
    },
    setCalculatorApiVersionInfo(state, version) {
        state.calculatorApiVersionInfo = version;
    },
};

//
// ACTIONS
//
const actions = {
    /* Fetch the backend API version, store it and print it out to the screen. */
    async fetchApiVersionInfo({commit}) {
        const version = await this._vm.$http.get(`version`);

        if (version.status == 200) {
            commit('setApiVersionInfo', version.data);
            console.log('Backend API Version: ' + version.data.version);
            console.log('Backend API Build Timestamp: ' + version.data.buildTimeStamp);
        } else {
            console.log('error fetching API info');
            console.log(version.status);
        }
    },
    async fetchCalculatorApiVersionInfo({commit}) {
        const {data: version} = await axios.get(CALCULATOR_API.BASE_URL);
        commit('setCalculatorApiVersionInfo', JSON.parse(version.split('k:')[1]));
    },
};

//
// DEFAULT EXPORT
//
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
