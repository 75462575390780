//Note permsToCheck are handled as AND while object values for a permToCheck will be an OR
export function checkPermissions(permsToCheck, permissions, accessPermissions) {
    let result = Object.entries(permsToCheck).some(([permToCheckName, permToCheckValue]) => {
        //permToCheckValue can be a permission name or an object with keys for permission names
        permToCheckValue =
            typeof permToCheckValue === 'string' ? [permToCheckValue] : permToCheckValue;

        //Does the permission actually exist?
        let permissionObj = permissions[permToCheckName];
        if (!permissionObj) return false;

        //Check that permToCheckValue is in the list of possible values for this permission
        if (
            !permToCheckValue.every((value) =>
                permissionObj.permissionOptions.some((p) => p.permissionCode === value)
            )
        )
            return false;

        //If the user's accessPermission for this permission doesn't contain
        //  at least one of the values in permToCheckValue then they failed the check
        if (!permToCheckValue.includes(accessPermissions[permToCheckName])) return false;

        return true;
    });

    return result;
}
