const state = () => ({
    preOpReport: null,
});

const getters = {
    preOpReport: (state) => state.preOpReport,
};

const mutations = {
    setPreOpReport(state, report) {
        state.preOpReport = report;
    },
};

const actions = {
    async fetchPreOpReport({commit}, {preOpDataSetId}) {
        try {
            const response = await this._vm.$http.get(`preop/${preOpDataSetId}/print`);

            if (response.data.error) {
                console.log(response.data.error);
                throw new Error('PreOpReport- Error obj defined');
            }

            if (response.data.results === undefined) {
                throw new Error('PreOpReport- Results undefined');
            }

            if (response.data.success !== true) {
                throw new Error('PreOpReport- Success not true');
            }

            if (response.status !== 200) {
                throw new Error('PreOpReport- Response not 200');
            }

            /*if (response.statusText !== 'OK') {
                throw new Error('PreOpReport- Response statusText not OK');
            }*/

            //Set eye OD first. So need to swap order
            if (response.data.results.eyes.length > 1) {
                let eyes = [response.data.results.eyes[1], response.data.results.eyes[0]];
                response.data.results.eyes = eyes;
            }

            commit('setPreOpReport', response.data.results);
        } catch (err) {
            console.log('Unable to fetch PreOpReport: ');
            console.log(err);
            throw new Error('Unable to fetch PreOpReport');
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
