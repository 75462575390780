//
// STATE
//
const state = () => ({
    inventoryList: null,
});

//
// GETTERS
//
const getters = {
    inventoryList: (state) => state.inventoryList,
};

//
// MUTATIONS
//
const mutations = {
    // Set the log
    setInventory(state, inventoryList) {
        state.inventoryList = inventoryList;
    },
    emptyList(state) {
        if (state.inventoryList && state.inventoryList.length > 0) {
            state.inventoryList.splice(0, state.inventoryList.length);
        }
    },
};

//
// ACTIONS
//
const actions = {
    // Load the inventory
    async fetchInventory({commit}) {
        let urlString = `inventory/consignment`; //?customerNumber=${customerNumber}`;
        const inventoryList = (await this._vm.$http.get(urlString)).data.results;
        commit('setInventory', inventoryList);
    },
    clearList({commit}) {
        commit('emptyList');
    },
};

//
// DEFAULT EXPORT
//
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
