const state = {
    loading: false,
};

const getters = {};

const mutations = {};

const actions = {
    async submit(_, payload) {
        const items = payload.items.filter((item) => item.quantity > 0);
        const {data} = await this._vm.$http.post('orders', {...payload, items});
        return data;
    },
    async update(_, {orderHeaderId, status}) {
        const {data} = await this._vm.$http.post(`orders/${orderHeaderId}`, {status});
        return data;
    },
    async getReturnReasons(_) {
        const {data} = await this._vm.$http.get('orders/returnreasons');
        return data;
    },
    async updateMultiple(_, payload) {
        const {data} = await this._vm.$http.put(`orders`, payload);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
