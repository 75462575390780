import Vue from 'vue';
import VueAxios from 'vue-plugin-axios';
import axios from 'axios';
import router from '@/router';
import store from '@/store';
import {ZoneCodeLocalStorageKey} from '@/constants/zone';

Vue.use(VueAxios, {
    axios,
    config: {
        baseURL:
            process.env.NODE_ENV === 'development'
                ? process.env.VUE_APP_ORIONAPI_URL || 'http://localhost:33145/api/v1'
                : process.env.VUE_APP_ORIONAPI_URL,
        headers: {
            'Content-Type': 'application/json',
            patch: {
                'Content-Type': 'application/json',
            },
            post: {
                'Content-Type': 'application/json',
            },
            put: {
                'Content-Type': 'application/json',
            },
        },
    },
    interceptors: {
        async beforeRequest(config) {
            // If this is the poll request for the forced logout status, then
            // do not reset the idle timeout when the token is refreshed.
            const resetIdleTimeout = !(
                config.url === 'users/me/forcedlogoutstatus' && config.method === 'get'
            );

            // Add auth header if a token has been obtained
            const token = await Vue.updateToken(resetIdleTimeout);
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            // Add zone header if the zone is available
            const zoneCode = localStorage.getItem(ZoneCodeLocalStorageKey);
            if (zoneCode) {
                config.headers['X-Orion-Zone'] = zoneCode;
            }
            // Add customer header if the activeCustomerId is available
            const {customerId} = store.getters['user/activeCustomer'] || {};
            if (customerId) {
                config.headers['X-Orion-Customer'] = customerId;
            }

            if (config.url && config.url.includes && config.url.includes('v1.1')) {
                config.baseURL = config.baseURL.replace('/v1', '');
            }

            return config;
        },
        beforeResponseError(error) {
            const {response} = error;
            if (response?.status === 401) {
                router.push({name: 'Unauthorized'});
            }
        },
    },
});
