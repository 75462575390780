import store from '@/store/index';

/**
 * Initialize the app data
 *
 * This is usually called after a login, page refresh, or zone selection
 *
 */
export async function initAppData() {
    await store.dispatch('user/setMyForcedLogoutStatus', false);
    await store.dispatch('user/fetchUser');
    await store.dispatch('user/fetchActiveCustomer');
    await store.dispatch('user/fetchDefaultCustomer');
    await store.dispatch('user/fetchActiveDoctor');
    await store.dispatch('user/fetchDefaultDoctor');
    await store.dispatch('user/fetchMyLanguages');
    await store.dispatch('permissions/fetchList');
    await store.dispatch('cart/fetchItemsCount');
}
