<template>
    <b-modal
        id="acknowledgement"
        centered
        no-fade
        hide-backdrop
        no-close-on-backdrop
        no-close-on-esc
        visible
        size="md"
        content-class="acknowledgement-content"
        header-class="acknowledgement-header"
        body-class="acknowledgement-body"
        footer-class="acknowledgement-footer"
    >
        <template #modal-header>
            {{ t('acknowledgement') }}
        </template>
        <template #default>
            <b-container>
                <b-row>
                    <b-col>
                        <p v-html="t(`acknowledgement_Message_${currentZone}`)"></p>
                    </b-col>
                </b-row>
            </b-container>
        </template>
        <template #modal-footer>
            <b-container>
                <b-row>
                    <b-col>
                        <b-button block variant="primary" @click="onAcknowledge">
                            {{ t('acknowledgementAndContinue') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </template>
    </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
/**
 * Forces the user to acknowledge the displayed message.
 *
 * @emits acknowledgement-made Emitted when the user acknowledges the message.
 */
export default {
    name: 'AcknowledgementModal',
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters('zone', ['currentZone']),
    },
    methods: {
        /**
         * Handle the acknowledgement
         */
        onAcknowledge() {
            this.$emit('acknowledgement-made');
        },
    },
};
</script>

<style lang="scss">
@import '../assets/css/variables';
@import '../assets/css/mixins';

#acknowledgement {
    .text-black.heavy,
    .text-gray-darker.heavy {
        @include heavy;
    }

    .acknowledgement {
        &-content {
            box-shadow: 0px 12px 30px rgb(0 0 0 / 24%);
        }

        &-header {
            padding-left: 2rem;
            background-color: $primary-dark;
            font-size: 2rem;
            color: #fff;
        }

        &-body {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
            padding-bottom: 0rem;
        }

        &-footer {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0rem;
            padding-bottom: 1rem;
        }
    }
}
</style>
