<template>
    <b-modal
        :id="'modal-warning-' + ident"
        hide-header
        :body-bg-variant="config.bgVariant"
        :body-text-variant="config.textVariant"
        :footer-bg-variant="config.bgVariant"
        :footer-text-variant="config.textVariant"
        :ok-variant="config.okVariant"
        @ok="config.ok"
        @cancel="config.cancel"
        @show="onShow"
        @hidden="onHidden"
        :ok-title="config.okTitle"
        :cancel-title="config.cancelTitle"
        :ok-only="!config.cancelTitle"
        no-close-on-backdrop
        no-close-on-esc
        :dialog-class="positionClass"
    >
        <div class="d-inline-block d-sm-flex align-items-center text-center">
            <b-icon-exclamation-triangle-fill class="mr-4" font-scale="4" />
            <div class="text-break text-left">
                <h3 class="heavy">{{ config.title }}</h3>
                <span v-html="config.text"></span>
            </div>
        </div>
    </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    name: 'WarningModal',

    props: {
        ident: String,
    },

    computed: {
        ...mapGetters({
            config: 'message/warningConfig',
        }),

        positionClass() {
            if (this.config.position === 'right') {
                return 'display-right';
            } else if (this.config.position === 'left') {
                return 'display-left';
            }
            return '';
        },
    },
    methods: {
        onShow() {
            const element = document.querySelector('div.b-toaster.b-toaster-top-right');
            if (this.config.position === 'right' && element) {
                element.classList.add('modal-warning-global-activated');
            }
        },
        onHidden() {
            const element = document.querySelector('div.b-toaster.b-toaster-top-right');
            if (element) {
                element.classList.remove('modal-warning-global-activated');
            }
        },
    },
};
</script>

<style lang="scss">
#modal-warning-global {
    .modal-body {
        border-radius: 16px 16px 0 0;
    }

    .display-left {
        margin-left: 1.75rem;
    }

    .display-right {
        margin-right: 1.75rem;
    }
}
</style>
