export default {
    namespaced: true,
    state: {
        endpointOverride: 'roles/permissions',
    },
    getters: {
        //Helper getter that reformats the list state variable
        //  to be more useful.
        permissions: (state) => {
            let output = {};
            for (var i = 0; i < state.list.length; i++) {
                let permission = state.list[i];

                //Check to see if the permission has already been added
                let item = {};
                if (output[permission.accessFeatureCode] == undefined) {
                    item = {
                        accessFeatureId: permission.accessFeatureId,
                        accessFeatureName: permission.accessFeatureName,
                        accessFeatureCode: permission.accessFeatureCode,
                        selected: 0,
                        permissionOptions: [],
                    };

                    output[permission.accessFeatureCode] = item;
                } else {
                    item = output[permission.accessFeatureCode];
                }

                //Add the permission to output
                item.permissionOptions.push({
                    value: permission.permissionId,
                    text: permission.permissionName,
                    permissionCode: permission.permissionCode,
                    permissionId: permission.permissionId,
                    permissionName: permission.permissionName,
                });
            }

            return output;
        },
    },
};
