import Keycloak from 'keycloak-js';
import store from '../store/index';

export default function install(Vue, config) {
    let timeoutRef;
    // create keycloak instance
    const _keycloak = Keycloak(config);

    // Clean up any app-related data
    function cleanUp() {
        store.commit('zone/removeCurrentZone');
        store.commit('user/removeMyAcknowledgement');
        store.commit('preopdata/resetPreopdata');
        localStorage.removeItem('pl-query-params');
    }

    function logout() {
        cleanUp();
        _keycloak.logout({redirectUri: window.location.origin});
    }

    async function updateToken(resetIdleTimeout = true) {
        // await update
        const refreshed = await _keycloak.updateToken(60);

        if (resetIdleTimeout) {
            // Schedule the next update if not activity
            //shall occur 1 min before refresh token expires
            const timeUntilExpiration =
                _keycloak.refreshTokenParsed.exp - _keycloak.refreshTokenParsed.iat - 60;
            var t = new Date();
            t.setSeconds(t.getSeconds() + timeUntilExpiration);
            clearTimeout(timeoutRef);
            timeoutRef = setTimeout(logout, timeUntilExpiration * 1000); // Update every 1 minutes (adjust as needed)a
        }

        //if (refreshed) {
        //    console.log('token refreshed , timout expires: ', t.toLocaleTimeString());
        //}

        // return possibly new token
        return _keycloak.token;
    }

    // Called after the user has been logged out by Keycloak, e.g. when the
    // SSO session timeout is reached. It will NOT be called when the user
    // does a manual logout from the app, e.g. by calling the logout() function
    // below. A manual logout causes a redirect and this handler is no longer
    // visible at that time.
    _keycloak.onAuthLogout = function () {
        cleanUp();
    };

    // add as global attribute
    Vue.$keycloak = _keycloak;

    // global async update function, returning new token
    Vue.updateToken = updateToken;

    // add getter to prototype
    Object.defineProperties(Vue.prototype, {
        $keycloak: {
            get() {
                return _keycloak;
            },
        },

        isAuthenticated: {
            get() {
                return _keycloak.authenticated;
            },
        },
    });

    // Clean up any app-related data and log the user out of Keycloak. This
    // will return them to the Keycloak login.
    Vue.prototype.logout = logout;
}
