const state = {};

const getters = {};

const mutations = {};

const actions = {
    // Update the user data
    async updateUser(_, userData) {
        let urlString = 'users';
        let response;

        try {
            response = await this._vm.$http.post(urlString, userData);

            if (response.data.error) {
                console.log(response.data.error);
                throw new Error('Update User- Error obj defined');
            }

            if (response.data.results === undefined) {
                throw new Error('Update User- Results undefined');
            }

            if (response.data.success !== true) {
                throw new Error('Update User- Success not true');
            }

            if (response.status !== 200) {
                throw new Error('Update User- Response not 200');
            }

            /* For some reason this doesn't always work on the new 
            // AWS deployments so commenting out.
            if (response.statusText !== 'OK') {
                throw new Error('Update User- Response statusText not OK');
            }*/
        } catch (err) {
            console.log('Unable to Update User: ');
            console.log(err);
            throw new Error('Unable to Update User');
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
