<template>
    <b-modal
        id="zone-selector"
        centered
        no-fade
        hide-backdrop
        no-close-on-backdrop
        no-close-on-esc
        visible
        size="sm"
        content-class="zone-selector-content"
        header-class="zone-selector-header"
        body-class="zone-selector-body"
        footer-class="zone-selector-footer"
    >
        <template #modal-header> Select... </template>
        <template #default>
            <b-container>
                <b-row>
                    <b-col>
                        <b-form-group label="Zones" label-for="select-zone">
                            <b-form-select
                                id="select-zone"
                                v-model="selectedZoneCode"
                                :options="zoneOptions"
                            >
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-container>
        </template>
        <template #modal-footer>
            <b-container>
                <b-row>
                    <b-col>
                        <b-button
                            block
                            variant="primary"
                            :disabled="submitDisabled"
                            @click="onSubmit"
                        >
                            Submit
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </template>
    </b-modal>
</template>

<script>
/**
 * Allows the user to select a zone to experience the app in
 *
 * @emits zone-selected Emitted when the user selects a zone.
 *   The selected zone is sent with the event.
 */
export default {
    name: 'ZoneSelectorModal',
    components: {},
    props: {
        /* The zones available to the user */
        zones: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedZoneCode: null,
        };
    },
    computed: {
        submitDisabled() {
            return !this.selectedZoneCode;
        },
        zoneOptions() {
            let zoneOptionsLocal = this.zones.map((z) => {
                return {
                    value: z.code,
                    text: z.name,
                };
            });
            zoneOptionsLocal.unshift({
                value: null,
                text: 'Please select a zone',
            });
            return zoneOptionsLocal;
        },
    },
    methods: {
        onSubmit() {
            let zone = this.zones.find((z) => z.code === this.selectedZoneCode);
            this.$emit('zone-selected', zone);
        },
    },
};
</script>

<style lang="scss">
@import '../assets/css/variables';
@import '../assets/css/mixins';

#zone-selector {
    .text-black.heavy,
    .text-gray-darker.heavy {
        @include heavy;
    }

    .zone-selector {
        &-content {
            box-shadow: 0px 12px 30px rgb(0 0 0 / 24%);
        }

        &-header {
            padding-left: 2rem;
            background-color: $primary-dark;
            font-size: 2rem;
            color: #fff;
        }

        &-body {
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 1rem;
            padding-bottom: 0rem;
        }

        &-footer {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0rem;
            padding-bottom: 1rem;
        }
    }
}
</style>
