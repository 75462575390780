/**
 * Determines if the given object and value of object[key] is defined and
 * not blank
 *
 * @param {Object} obj the object under consideration
 * @param {String} key the key under consideration
 * @param {String} failStr the failure message
 * @returns if true, the object[key] value; if false, the failure message
 */
export function definedAndNotBlankObj(obj, key, failStr) {
    if (obj == undefined) return failStr;

    if (obj[key] == undefined) return failStr;

    if (typeof obj[key] == 'string' && obj[key].length == 0) return failStr;

    return obj[key];
}

/**
 * Map or tranform properties from a given object
 *
 * @param {Object} obj the object under consideration
 * @param {String} callback the transform function for each property value
 * @returns new object with the formatted values
 */
export function mapObjectRecursively(obj, callback, ignoreProps) {
    let newObj = {};
    for (let key in obj) {
        if (ignoreProps.includes(key)) {
            newObj[key] = obj[key];
        } else if (typeof obj[key] === 'object') {
            newObj[key] = mapObjectRecursively(obj[key], callback, ignoreProps);
        } else {
            newObj[key] = callback(obj[key]);
        }
    }
    return newObj;
}
