<template>
    <div id="app" class="h-100 d-flex flex-column" :key="$i18next.language">
        <div v-if="showZoneSelector">
            <ZoneSelectorModal :zones="myZones" @zone-selected="onZoneSelected" />
        </div>
        <!-- Surgeon / Customer Selector -->
        <div v-if="showSurgeonCustomerSelector">
            <SurgeonCustomerSelectorModal
                :zone="currentZone"
                @cancel="onCustomerSelectionCancel"
                @customer-selected="onCustomerSelected"
            />
        </div>
        <div v-if="showAcknowledgementModal">
            <AcknowledgementModal @acknowledgement-made="onAcknowledgementMade" />
        </div>
        <div v-if="showGdprModal">
            <GdprModal
                :show-gdpr-modal.sync="showGdprModal"
                :gdpr-message-count="currentUser.gdprMessageCount"
                @on-accepted="onGdprAccepted"
                @on-denied="signOutClicked"
            />
        </div>
        <!-- The universal header navbar. -->
        <b-navbar id="mainNavbar" v-if="!$route.meta.hideNavbar" type="light" sticky>
            <b-navbar-brand to="/" class="d-flex align-items-center ml-2 nav-logo">
                <img src="./assets/stella-logo.png" width="30" :alt="t('orionLogoAlt')" />
            </b-navbar-brand>

            <!--<button @click="decrementRootFontSize">-</button>
            <div class="mx-2">ROOT FONT SIZE</div>
            <div class="mr-2">{{ rootFontSize }}</div>
            <button @click="incrementRootFontSize">+</button>-->

            <div class="nav-contents" :class="{'nav-expanded': navbarExpanded}">
                <b-navbar-nav class="mobile-user-items" :class="{'nav-expanded': navbarExpanded}">
                    <!-- Avatar (collapse 2 only) -->
                    <b-nav-item-dropdown right no-caret menu-class="dropdown-menu-center">
                        <template #button-content>
                            <b-img
                                rounded="circle"
                                height="32"
                                :src="userThumbnailUrl"
                                :alt="t('userAlt')"
                            ></b-img>
                        </template>
                        <!--
                        <template v-if="currentUser">
                            
                            <b-dropdown-item :disabled="true">
                                <b>Email:</b>
                                {{ get(currentUser, 'email') }}</b-dropdown-item
                            >
                            <b-dropdown-item :disabled="true">
                                <b>Role:</b>
                                {{ get(currentUser, 'role.name') }}</b-dropdown-item
                            >
                        </template>-->
                        <b-dropdown-item @click="signOutClicked">
                            {{ t('signOut') }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>

                    <!-- Surgeon/Customer display (collapse 2 only) -->
                    <b-nav-text class="w-100">
                        <b-button
                            block
                            variant="white"
                            class="btn-surgeon-customer-selector"
                            @click="onCustomerButtonClick"
                        >
                            <span class="text-gray-darker" v-if="activeCustomer">{{
                                activeCustomer.name
                            }}</span>
                            <span class="text-gray-dark" v-if="activeSurgeon">
                                - {{ activeSurgeon.fullName }}</span
                            >
                            <b-icon icon="chevron-down" class="text-gray-darker ml-3"></b-icon>
                        </b-button>
                    </b-nav-text>
                </b-navbar-nav>

                <div class="nav-contents-inner">
                    <!-- Left aligned content. Second stage to collapse -->
                    <b-navbar-nav class="nav-collapse-2" :class="{'nav-expanded': navbarExpanded}">
                        <b-nav-item
                            to="/"
                            :active="$route.name === 'Dashboard'"
                            :disabled="$route.name === 'Dashboard'"
                            ><b-icon
                                icon="grid-1x2-fill"
                                class="pointer text-gray-dark mx-2 collapse-only"
                            />{{ t('dashboard') }}</b-nav-item
                        >
                        <b-nav-item
                            :to="{name: 'PatientList'}"
                            :active="$route.name === 'PatientList'"
                            :disabled="$route.name === 'PatientList'"
                        >
                            <b-icon
                                icon="people-fill"
                                class="pointer text-gray-dark mx-2 collapse-only"
                            />
                            {{ t('patients') }}</b-nav-item
                        >
                        <b-nav-item
                            v-if="hasOrderPermission && !isDistributorSurgeonMode"
                            :to="{name: 'OrderList'}"
                            :active="$route.name === 'OrderList'"
                            :disabled="$route.name === 'OrderList'"
                            ><b-icon
                                icon="receipt"
                                class="pointer text-gray-dark mx-2 collapse-only"
                            />
                            {{ t('orders') }}
                        </b-nav-item>
                        <b-nav-item
                            v-if="isDistributorSurgeonMode"
                            :to="{name: 'PreOrderList'}"
                            :active="$route.name === 'PreOrderList'"
                            :disabled="$route.name === 'PreOrderList'"
                            ><b-icon
                                icon="receipt"
                                class="pointer text-gray-dark mx-2 collapse-only"
                            />{{ t('preorders') }}</b-nav-item
                        >
                        <b-nav-item
                            :to="{name: 'ReserveList'}"
                            :active="$route.name === 'ReserveList'"
                            :disabled="$route.name === 'ReserveList'"
                            ><b-icon
                                icon="receipt"
                                class="pointer text-gray-dark mx-2 collapse-only"
                            />{{ t('reserve') }}</b-nav-item
                        >
                        <b-nav-item
                            v-if="hasSurgeonsPermission"
                            :to="{name: 'SurgeonList'}"
                            :active="$route.name === 'SurgeonList'"
                            :disabled="$route.name === 'SurgeonList'"
                        >
                            <b-icon
                                icon="person-lines-fill"
                                class="pointer text-gray-dark mx-2 collapse-only"
                            />{{ t('surgeons') }}</b-nav-item
                        >
                        <b-nav-item
                            :to="{name: 'InventoryList'}"
                            v-if="hasWarehouse"
                            :active="$route.name === 'InventoryList'"
                            :disabled="$route.name === 'InventoryList'"
                        >
                            <b-icon
                                icon="person-lines-fill"
                                class="pointer text-gray-dark mx-2 collapse-only"
                            />{{ t('inventory') }}
                        </b-nav-item>

                        <b-nav-text
                            v-if="showAdminMenu"
                            class="border-bottom pt-1 mb-2 w-100 collapse-only"
                        ></b-nav-text>

                        <b-nav-item
                            v-if="showAdminMenu"
                            :to="{name: hasUsersPermission ? 'UserList' : 'CustomerList'}"
                            :active="['UserList', 'CustomerList'].includes($route.name)"
                            :disabled="['UserList', 'CustomerList'].includes($route.name)"
                            ><b-icon
                                icon="key-fill"
                                class="pointer text-gray-dark mx-2 collapse-only"
                            />
                            {{ t('admin') }}
                        </b-nav-item>
                    </b-navbar-nav>

                    <!-- Right aligned content. First stage to collapse -->
                    <b-navbar-nav class="nav-collapse-1" :class="{'nav-expanded': navbarExpanded}">
                        <!-- UAC disabled as per #2171
                    <b-nav-item to="/UAC" class="align-self-md-center" v-if="canViewLog"
                        ><b-icon icon="card-text" class="pointer text-gray-dark mx-2 d-md-none" />
                        {{ t('uac') }}</b-nav-item
                    >-->

                        <div class="user-items collapse-center collapse-2-hide">
                            <!-- Avatar with dropdown -->
                            <b-nav-item-dropdown right no-caret class="ml-2">
                                <template #button-content>
                                    <b-img
                                        rounded="circle"
                                        height="32"
                                        :src="userThumbnailUrl"
                                        :alt="t('userAlt')"
                                    ></b-img>
                                </template>
                                <!--
                                <template v-if="currentUser">
                                    <b-dropdown-item :disabled="true">
                                        <b>Email:</b>
                                        {{ get(currentUser, 'email') }}</b-dropdown-item
                                    >
                                    <b-dropdown-item :disabled="true">
                                        <b>Role:</b>
                                        {{ get(currentUser, 'role.name') }}</b-dropdown-item
                                    >
                                </template>
                                -->
                                <b-dropdown-item @click="signOutClicked">
                                    {{ t('signOut') }}
                                </b-dropdown-item>
                            </b-nav-item-dropdown>

                            <!-- Surgeon/Customer Selector -->
                            <b-nav-text>
                                <b-button
                                    variant="white"
                                    class="btn-surgeon-customer-selector ml-2"
                                    v-b-tooltip.hover.html
                                    :disabled="!get(firstPageOfCustomers, 'length')"
                                    :title="customerSelectorTooltip"
                                    @click="onCustomerButtonClick"
                                >
                                    <span class="text-gray-darker" v-if="activeCustomer">{{
                                        activeCustomer.name
                                    }}</span>
                                    <span class="text-gray-dark" v-if="activeSurgeon">
                                        - {{ activeSurgeon.fullName }}</span
                                    >
                                    <b-icon
                                        icon="chevron-down"
                                        class="text-gray-darker ml-3"
                                    ></b-icon>
                                </b-button>
                            </b-nav-text>
                        </div>

                        <div class="action-items">
                            <!-- Print Report -->
                            <b-nav-item
                                @click="printReport"
                                class="pointer"
                                v-if="$route.name == 'PreOpData'"
                            >
                                <b-button id="printReport" :disabled="isPrintDisabled">
                                    {{ t('preOpPrintReport') }}
                                    <b-icon icon="printer-fill" class="ml-1"></b-icon>
                                </b-button>
                            </b-nav-item>

                            <!-- Shopping Cart -->
                            <b-nav-item
                                key="shoppingcart"
                                to="/shoppingcart"
                                v-if="showShoppingCartButton"
                            >
                                <b-button variant="success">
                                    {{ t('checkout') }} - {{ shoppingCartItemsCount }}
                                    <b-icon icon="cart" class="ml-1" />
                                </b-button>
                            </b-nav-item>

                            <!-- Help -->
                            <b-nav-item
                                key="help"
                                class="btn-help"
                                v-if="showHelp"
                                link-classes="d-flex align-items-center"
                                @click="goToHelp"
                            >
                                <b-icon
                                    width="2em"
                                    height="2em"
                                    class="pointer text-gray-dark mx-2"
                                    icon="question-circle-fill"
                                />
                                <span class="collapse-only">{{ t('help') }}</span>
                            </b-nav-item>

                            <!-- Sign out button -->
                            <b-nav-text
                                class="border-bottom pt-1 mb-2 w-100 collapse-2-only"
                            ></b-nav-text>
                            <b-nav-item class="collapse-2-only" @click="signOutClicked">
                                <b-icon icon="x-circle-fill" class="pointer text-gray-dark mx-2" />
                                {{ t('signOut') }}
                            </b-nav-item>
                        </div>
                    </b-navbar-nav>
                </div>
            </div>

            <!-- Collapse toggle -->
            <button
                @click="navbarExpanded = !navbarExpanded"
                class="icon-btn icon-btn-gray-dark nav-collapse-toggle"
            >
                <b-icon-list font-scale="1.3" />
            </button>
        </b-navbar>

        <SpinnerOverlay v-show="showSpinner" />
        <WarningModal ident="global" />

        <!-- Blur overlay -->
        <b-overlay :show="showBlurOverlay" variant="transparent" opacity="1" blur="10px">
            <router-view :key="$route.path" class="flex-grow" />
            <!-- Remove the spinner in the overlay -->
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import has from 'lodash/has';
import {SurgeonCustomerSelectorLaunchSources as LaunchSources} from '@/constants/customer.js';
import {ZoneCodes, ZoneCodeLocalStorageKey} from '@/constants/zone';
import {initAppData} from '@/utilities/app';
import {getCheckoutRouteNames} from '@/utilities/router';
import SurgeonCustomerSelectorModal from '@/components/SurgeonCustomerSelectorModal';
import SpinnerOverlay from '@/views/layout/SpinnerOverlay';
import ZoneSelectorModal from './components/ZoneSelectorModal';
import AcknowledgementModal from './components/AcknowledgementModal';
import GdprModal from './components/GdprModal';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';
import i18n from '@/plugins/i18n';
import {HELP_ORIGIN} from '@/config';
/**
 * The initialization states. They are presented in the following order, as
 * necessary:
 *  - Zone Selector
 *  - GDPR
 *  - Customer Surgeon Selector
 *  - Acknowledgement
 */
const InitStates = Object.freeze({
    ZONE_SELECTOR: 'ZONE_SELECTOR',
    GDPR: 'GDPR',
    CUSTOMER_SURGEON_SELECTOR: 'CUSTOMER_SURGEON_SELECTOR',
    ACKNOWLEDGEMENT: 'ACKNOWLEDGEMENT',
});

export default {
    name: 'App',
    components: {
        SpinnerOverlay,
        SurgeonCustomerSelectorModal,
        ZoneSelectorModal,
        AcknowledgementModal,
        GdprModal,
    },
    data() {
        return {
            InitStates,
            info: null,
            currentInitState: null,
            rootFontSize: null,
            logoutUserWhenForcedInterval: null,
            /* Data items related to zone selection. */
            ZoneCodes,
            ZoneCodeLocalStorageKey,
            showZoneSelector: false,
            /* Data items related to customer/surgeon selection. */
            LaunchSources,
            showSurgeonCustomerSelector: false,
            surgeonCustomerSelectorLaunchSource: LaunchSources.LOGIN,
            firstPageOfCustomers: [],
            firstPageOfSurgeons: [],
            customer: null,
            surgeon: null,
            /* Data items related to the acknowledgement message. */
            showAcknowledgementModal: false,
            /* Data items related to the gdpr message. */
            showGdprModal: false,
            navbarExpanded: false,
        };
    },

    async mounted() {
        this.logoutUserWhenForced();

        if (isEmpty(this.currentUser)) {
            return;
        }

        await Promise.all([
            this.fetchMyCustomers({page: 1, per_page: 30, offset: 0, query: ''}),
            this.fetchMyDoctors({page: 1, per_page: 30, offset: 0, query: ''}),
        ]);

        this.firstPageOfCustomers = this.customersFetched;
        this.firstPageOfSurgeons = this.surgeonsFetched;

        if (!this.currentZone) {
            await this.processZone();
        } else {
            await this.processActiveDefaultSurgeonCustomer();
        }

        this.currentInitState = this.InitStates.ZONE_SELECTOR;
    },

    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            currentZone: 'zone/currentZone',
            myZones: 'user/myZones',
            permissions: 'permissions/permissions',
            customersFetched: 'user/userCustomers/list',
            surgeonsFetched: 'user/userDoctors/list',
            defaultCustomerId: 'user/defaultCustomerId',
            defaultDoctorId: 'user/defaultDoctorId',
            activeCustomerId: 'user/activeCustomerId',
            activeDoctorId: 'user/activeDoctorId',
            userThumbnailUrl: 'user/userThumbnailUrl',
            canViewLog: 'logging/canViewLog',
            isPrintDisabled: 'preopdata/isPrintDisabled',
            myAcknowledgement: 'user/myAcknowledgement',
        }),
        ...mapState({
            activeCustomer: (state) => state.user.activeCustomer,
            activeSurgeon: (state) => state.user.activeDoctor,
            defaultCustomer: (state) => state.user.defaultCustomer,
            showSpinner: (state) => state.app.showSpinner,
            shoppingCartItemsCount: (state) => state.cart.itemsCount,
        }),
        hasOrderingPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERING]: [PERMISSIONS_VALUES.READ_WRITE],
            });
        },
        hasOrderPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERS]: [PERMISSIONS_VALUES.READ_ONLY, PERMISSIONS_VALUES.READ_WRITE],
            });
        },
        hasSurgeonsPermission() {
            return this.checkPermissions({
                [PERMISSIONS.SURGEONS]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                ],
            });
        },
        showAdminMenu() {
            return this.hasUsersPermission || this.hasCustomersPermission;
        },
        hasUsersPermission() {
            return this.checkPermissions({
                [PERMISSIONS.USERS]: PERMISSIONS_VALUES.READ_TYPES,
            });
        },
        hasCustomersPermission() {
            return this.checkPermissions({
                [PERMISSIONS.CUSTOMER_ADMIN]: PERMISSIONS_VALUES.WRITE_TYPES,
            });
        },
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.SURGEON],
            });
        },
        showBlurOverlay: function () {
            return (
                this.showZoneSelector ||
                this.showSurgeonCustomerSelector ||
                this.showAcknowledgementModal ||
                this.showGdprModal
            );
        },
        showShoppingCartButton: function () {
            return (
                !['ShoppingCart', ...getCheckoutRouteNames()].includes(this.$route.name) &&
                this.hasOrderingPermission
            );
        },
        hasWarehouse() {
            //set hasWarehouse if the activeCustomerWarehouseNumber is not null
            return get(this.currentUser, 'activeCustomerWarehouseNumber') != null;
        },
        showHelp() {
            return has(this.$route, ['meta', 'help']);
        },
        customerSelectorTooltip() {
            let customerSurgeonInfo = `${this.t('customer')}: ${
                this.currentUser?.activeOcosCustomerId
            }<br/>`;
            if (this.activeSurgeon) {
                customerSurgeonInfo += `${this.t('surgeon')}: ${this.activeSurgeon.ocosDoctorId}`;
            } else {
                customerSurgeonInfo += `${this.t('surgeon')}: ${this.t('all').toUpperCase()}`;
            }
            return customerSurgeonInfo + '<br/><br/>' + this.t('customerSelectorTooltip');
        },
    },

    watch: {
        async currentInitState(modal) {
            switch (modal) {
                case InitStates.ZONE_SELECTOR:
                    await this.processZone();
                    break;
                case InitStates.GDPR:
                    await this.processGdpr();
                    break;
                case InitStates.CUSTOMER_SURGEON_SELECTOR:
                    await this.processActiveDefaultSurgeonCustomer();
                    break;
                case InitStates.ACKNOWLEDGEMENT:
                    await this.processAcknowledgement();
                    break;
                default:
                    break;
            }
        },

        $route() {
            this.navbarExpanded = false;
        },
    },

    methods: {
        get,
        ...mapActions('user', [
            'fetchMyZones',
            'fetchMyForcedLogoutStatus',
            'setActiveCustomerId',
            'setActiveDoctorId',
            'setDefaultCustomerId',
            'setGdprAcceptance',
        ]),
        ...mapActions({
            fetchMyCustomers: 'user/userCustomers/fetchList',
            fetchMyDoctors: 'user/userDoctors/fetchList',
        }),
        ...mapActions('cart', ['fetchItemsCount']),
        ...mapMutations('zone', ['setCurrentZone']),
        ...mapMutations('user', ['setMyAcknowledgement']),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser?.accessPermissions
            );
        },
        goToHelp() {
            const helpRoute = [
                HELP_ORIGIN,
                this.currentUser.language,
                this.currentZone,
                get(this.$route, 'meta.help'),
            ];
            const windowWidth = window.screen.width - window.screen.width * 0.3;
            const windowHeight = window.screen.height - window.screen.height * 0.3;
            window.open(
                helpRoute.join('/').toLowerCase(),
                'newwindow',
                `width=${windowWidth},
                height=${windowHeight}
                left=${(window.screen.width - windowWidth) / 2},
                top=${(window.screen.height - windowHeight) / 2}`
            );
        },
        async languageChanged(languageKey) {
            await i18n.i18next.changeLanguage(languageKey);
        },
        /**
         * Process the zone
         *
         * If a zone has been set, go to the next initialization state. If not,
         * proceed with setting the zone: If the current user's zones include
         * all the zones in the app, allow the user to select which one to use.
         * Otherwise, look for each valid zone and use the first one found.
         */
        async processZone() {
            if (!this.currentZone) {
                await this.fetchMyZones();

                const zoneCodes = Object.keys(ZoneCodes);
                const myZoneCodes = this.myZones.map((z) => z.code);

                if (zoneCodes.every((z) => myZoneCodes.includes(z))) {
                    this.showZoneSelector = true;
                } else if (myZoneCodes.includes(ZoneCodes.OUS)) {
                    let zone = this.myZones.find((z) => z.code === ZoneCodes.OUS);
                    await this.onZoneSelected(zone);
                } else if (myZoneCodes.includes(ZoneCodes.US)) {
                    let zone = this.myZones.find((z) => z.code === ZoneCodes.US);
                    await this.onZoneSelected(zone);
                } else {
                    alert('No valid zone found for this user');
                }
            } else {
                this.currentInitState = this.InitStates.GDPR;
            }
        },

        /**
         * Process the display of GDPR message the user must accept before
         * continuing to the app. The message will only appear if the user has
         * not yet accepted it in the selected zone
         */
        async processGdpr() {
            // OUS zone need approval for two messages, we need two ensure both messages were already shown
            const messagesPerZone = {[ZoneCodes.US]: 0, [ZoneCodes.OUS]: 1};
            if (this.currentUser.gdprMessageCount > messagesPerZone[this.currentZone]) {
                this.currentInitState = this.InitStates.CUSTOMER_SURGEON_SELECTOR;
            } else {
                this.showGdprModal = true;
            }
        },

        /**
         * Process the active and default surgeon and customer
         *
         * First, if there is a default customer set but it is not in the
         * user's list of customers unset the default customer.
         *
         * Next, if the user has no customers then show an alert. If there is
         * a default selection, only one customer, or an existing active
         * customer ID, then use it. Otherwise, open the Surgeon/Customer
         * selector to allow the user to select a customer and optionally
         * a surgeon.
         */
        async processActiveDefaultSurgeonCustomer() {
            if (this.defaultCustomerId && !this.defaultCustomer) {
                await this.setDefaultCustomerId(null);
            }

            if (this.firstPageOfCustomers.length === 0) {
                alert('No customers found for this user');
            } else if (this.defaultCustomerId) {
                await this.setActiveCustomerId(this.defaultCustomerId);
                await this.blockUntilAllSettled([this.fetchItemsCount()]);

                if (this.defaultDoctorId) {
                    await this.setActiveDoctorId(this.defaultDoctorId);
                }

                this.currentInitState = this.InitStates.ACKNOWLEDGEMENT;
            } else if (this.firstPageOfCustomers.length === 1) {
                await this.setActiveCustomerId(this.firstPageOfCustomers[0]?.customerId);
                await this.blockUntilAllSettled([this.fetchItemsCount()]);

                if (this.firstPageOfSurgeons.length === 1) {
                    await this.setActiveDoctorId(this.firstPageOfSurgeons[0]?.doctorId);
                }

                this.currentInitState = this.InitStates.ACKNOWLEDGEMENT;
            } else if (this.activeCustomerId) {
                await this.blockUntilAllSettled([this.fetchItemsCount()]);

                this.currentInitState = this.InitStates.ACKNOWLEDGEMENT;
            } else {
                this.showSurgeonCustomerSelector = true;
            }
        },

        /**
         * Process the display of the message the user must acknowledge before
         * continuing to the app. The message will only appear if the user has
         * not yet acknowledged the message during the current logged-in session.
         */
        async processAcknowledgement() {
            if (this.myAcknowledgement !== 'true') {
                this.showAcknowledgementModal = true;
            }
        },

        /**
         * Handle when a zone is selected
         *
         * @param {Object} selectedZone
         */
        async onZoneSelected(selectedZone) {
            this.setCurrentZone(selectedZone.code);
            // using try/finally statement to make selector disappear if 401 was launched after selecting zone
            try {
                // This will refetch the initial app data so that it corresponds
                // to the current zone
                await initAppData();
                await Promise.all([
                    this.fetchMyCustomers({page: 1, per_page: 30, offset: 0, query: ''}),
                    this.fetchMyDoctors({page: 1, per_page: 30, offset: 0, query: ''}),
                ]);
                await this.languageChanged(this.currentUser.language);

                this.firstPageOfCustomers = this.customersFetched;
                this.firstPageOfSurgeons = this.surgeonsFetched;

                if (this.activeCustomerId) {
                    await this.setActiveCustomerId(null);
                }

                if (this.activeDoctorId) {
                    await this.setActiveDoctorId(null);
                }

                this.currentInitState = this.InitStates.GDPR;
            } finally {
                this.showZoneSelector = false;
            }
        },

        /**
         * Show the Surgeon Customer Selector modal when the customer button
         * is clicked
         */
        onCustomerButtonClick: function () {
            this.showSurgeonCustomerSelector = true;
            this.surgeonCustomerSelectorLaunchSource = LaunchSources.DASHBOARD;
        },

        /**
         * Handle the customer selection. This may also include a surgeon
         * selection.
         */
        onCustomerSelected: async function ({selectedCustomer, selectedSurgeon}) {
            localStorage.removeItem('pl-query-params');
            await this.blockingRequest('patientList/clearPatientList');
            await this.blockingRequest('userList/clearList');

            this.customer = selectedCustomer;
            this.surgeon = selectedSurgeon;
            await this.setActiveCustomerId(this.customer?.customerId);
            await this.setActiveDoctorId(this.surgeon?.doctorId);
            await this.fetchItemsCount();

            this.currentInitState = this.InitStates.ACKNOWLEDGEMENT;

            this.showSurgeonCustomerSelector = false;
        },

        /**
         * Handle the customer/surgeon selection cancellation depending on
         * where the modal was launched from
         */
        onCustomerSelectionCancel: function () {
            if (this.surgeonCustomerSelectorLaunchSource === LaunchSources.LOGIN) {
                this.signOutClicked();
                this.showSurgeonCustomerSelector = false;
            } else if (this.surgeonCustomerSelectorLaunchSource === LaunchSources.DASHBOARD) {
                this.showSurgeonCustomerSelector = false;
            }
        },

        /**
         * Handle when the acknowledgment is made
         */
        onAcknowledgementMade: function () {
            this.setMyAcknowledgement('true');
            this.showAcknowledgementModal = false;
        },

        /**
         * Handle when the GDPR is accepted
         */
        onGdprAccepted: async function (isCompleted) {
            this.setSpinner(true);
            const success = await this.setGdprAcceptance();
            if (success && isCompleted) {
                this.currentInitState = this.InitStates.CUSTOMER_SURGEON_SELECTOR;
            }
            this.setSpinner(false);
        },

        /**
         * Handle the user signing out. This will clear some data and then log
         * the user out of Keycloak. Once the user is logged out of Keycloak
         * there may be additional clean up e.g. removing the zone-code from
         * local storage.
         */
        signOutClicked: async function () {
            if (this.currentUser) {
                await this.setActiveCustomerId(null);
                await this.setActiveDoctorId(null);
            }
            this.$router.push({name: 'LoggingOut'});
        },

        printReport() {
            const routeData = this.$router.resolve({
                name: 'PreOpReport',
                params: {preOpDataSetId: this.$route.params.preOpDataSetId},
            });

            window.open(routeData.href, '_blank');
        },
        incrementRootFontSize: function () {
            this.rootFontSize++;
            document.documentElement.style.fontSize = `${this.rootFontSize}` + 'px';
        },
        decrementRootFontSize: function () {
            this.rootFontSize--;
            document.documentElement.style.fontSize = `${this.rootFontSize}` + 'px';
        },
        /** Polls the current user's forced logout status and logs them out
         * if it is true.
         */
        logoutUserWhenForced: function () {
            this.logoutUserWhenForcedInterval = setInterval(async () => {
                await this.fetchMyForcedLogoutStatus();
                if (this.currentUser.forcedLogoutStatus) {
                    clearInterval(this.logoutUserWhenForcedInterval);
                    this.$router.push({name: 'LoggingOut'});
                }
            }, 10000);
        },
    },

    destroyed() {
        clearInterval(this.logoutUserWhenForcedInterval);
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

$nav-collapse-breakpoint-1: 1200px;
$nav-collapse-breakpoint-2: 800px;

#mainNavbar {
    padding: 0 1rem;
    justify-content: space-between;
    align-items: start;

    .nav-logo {
        padding: 0.75rem 0;
    }
}

.nav-contents {
    width: 100%;
    align-self: center;
    flex-grow: 2;

    .nav-contents-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .collapse-only {
        display: none;
    }
}

.nav-contents-inner {
    display: flex;
    align-items: center;
    width: 100%;
}

.nav-collapse-toggle {
    display: none;
    width: 30px;
    height: 30px;
    padding: 0.75rem 0;
    margin-left: 0.5rem;
}

.mobile-user-items {
    display: none;
}

.nav-collapse-1 {
    .user-items {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        order: 5;
    }

    .action-items {
        display: flex;
        align-items: center;
    }

    .collapse-2-only {
        display: none;
    }

    @media (max-width: #{$nav-collapse-breakpoint-1}) {
        display: none;

        &.nav-expanded {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100vw - 92px);
            margin-right: -54px;
        }

        .collapse-only {
            display: unset;
        }

        .collapse-center {
            align-self: center;
        }
    }

    @media (max-width: #{$nav-collapse-breakpoint-2}) {
        .collapse-2-only {
            display: unset;
        }

        .collapse-2-hide {
            display: none;
        }

        &.nav-expanded {
            margin-right: 0;

            .action-items {
                flex-direction: column;
                align-items: start;
                justify-content: start;
                width: 100%;
            }
        }
    }
}

.nav-collapse-2 {
    align-items: center;

    @media (max-width: #{$nav-collapse-breakpoint-2}) {
        display: none;
        align-items: start;

        &.nav-expanded {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .collapse-only {
            display: unset;
        }

        .collapse-center {
            align-self: center;
        }
    }
}

@media (max-width: #{$nav-collapse-breakpoint-1}) {
    .nav-collapse-toggle {
        display: block;
    }

    .nav-contents {
        .nav-contents-inner {
            flex-direction: column;
            align-items: start;
        }

        &.nav-expanded {
            margin-top: 9px;
            padding-bottom: 0.75rem;
        }
    }
}

@media (max-width: #{$nav-collapse-breakpoint-2}) {
    .nav-contents {
        width: 100vw;
        margin-left: -52px;
        margin-right: -34px;

        .nav-contents-inner {
            flex-direction: row;
            justify-content: space-between;
            align-items: start;

            .nav-collapse-1,
            .nav-collapse-2 {
                flex-basis: 1;
                width: 50%;
            }

            .nav-collapse-1 .action-items {
                align-items: end;
            }
        }

        &.nav-expanded {
            .mobile-user-items {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

.btn-surgeon-customer-selector.btn-white {
    background-color: $white;
    border-color: $gray-dark;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (max-width: 1400px) {
        max-width: 280px;
    }

    @media (max-width: #{$nav-collapse-breakpoint-2}) {
        width: 100%;
        max-width: 100%;
    }
}

#printReport {
    color: white;
    background-color: #616469;
}

button#printReport[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
}
</style>
