import snakeCase from 'lodash/snakeCase';

const MAX_NOT_LOADED_RECORDS = 500;

const state = () => ({
    loading: false,
    patientList: [],
    queryParams: {},
    /* Page details for the table of the patient list.
    - The total number of patients/rows  (pageDetails.total)
    - The total number of pages (pageDetails.totalPages)
  */
    pageDetails: {},
});

const getters = {
    patientList: (state) => state.patientList,
    pageDetails: (state) => state.pageDetails,
    loading: (state) => state.loading,
    canLoadMore: (state) =>
        state.queryParams.currentPage * state.queryParams.perPage < state.pageDetails.total &&
        !state.loading,
};

const mutations = {
    reset(state) {
        state.patientList = [];
        state.pageDetails = {};
        state.queryParams = {};
    },
    setLoading(state, isLoading) {
        state.loading = isLoading;
    },
    setQueryParams(state, queryParams) {
        state.queryParams = queryParams;
    },
    // use an object to set the page & patient list
    setPatientInfo(state, {list, total, totalPages}) {
        /**
         * If we are paging a large amount of data, say thousands of data,
         * the browser freezes when we create too many elements in the dom,
         * so we need to set a maximum limit of elements to create
         */
        const isHugeAmount = total > MAX_NOT_LOADED_RECORDS;
        if (state.queryParams.currentPage === 1 || !state.patientList.length) {
            const length = isHugeAmount ? MAX_NOT_LOADED_RECORDS : total;
            state.patientList = Array.from({length}, () => ({}));
        }
        const startAt = (state.queryParams.currentPage - 1) * state.queryParams.perPage;
        state.patientList.splice(startAt, state.queryParams.perPage, ...list);
        state.pageDetails = {total, totalPages};
        if (isHugeAmount && state.patientList.length < total) {
            state.patientList.push(...Array.from({length: state.queryParams.perPage}, () => ({})));
        }
    },
};

const actions = {
    /* Fetch the list of patients with the specified parameters. */
    async fetchPatientList({commit}, payload) {
        const {currentPage, perPage, search, offset, sortBy, sortOrder} = payload;

        commit('setLoading', true);
        commit('setQueryParams', payload);

        var params = '';
        if (currentPage) {
            params = `?page=${currentPage}`;
        }

        if (perPage) {
            params += `&per_page=${perPage}`;
        }
        if (search) {
            params += `&search=${search}`;
        }
        if (offset) {
            params += `&offset=${offset}`;
        }
        if (sortBy) {
            params += `&sort_by=${snakeCase(sortBy)}`;
        }
        if (sortOrder) {
            params += `&sort_order=${sortOrder}`;
        }

        const response = await this._vm.$http.get(`patients/summaries${params}`);

        if (response.status == 200) {
            const info = {
                list: response.data.results,
                total: response.headers['x-orion-total'],
                totalPages: response.headers['x-orion-totalpages'],
            };
            commit('setPatientInfo', info);
        } else {
            console.log('Invalid response when fetching patient list.');
            commit('reset');
        }
        commit('setLoading', false);
    },

    clearPatientList({commit}) {
        commit('reset');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
