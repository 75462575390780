//
// STATE
//
const state = {};

//
// GETTERS
//
const getters = {};

//
// MUTATIONS
//
const mutations = {};

//
// ACTIONS
//
const actions = {
    async fetchLensCertificationSummary(_, doctorId) {
        let urlString = `doctors/${doctorId}/certificationsummary `;
        const {data} = await this._vm.$http.get(urlString);
        return data;
    },
    async update(_, {doctorId, active, notes}) {
        let urlString = `doctors/${doctorId} `;
        const {data} = await this._vm.$http.post(urlString, {active, notes});
        return data;
    },
};

//
// DEFAULT EXPORT
//
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
