import {escapeRegExp} from 'lodash';
import {SearchResultProperties} from '@/constants/search';

/**
 * Searches the given objects and sets properties on the objects to
 * indicate where the text was found. This is initially used in
 * the SearchText component to add selective styling to the searched
 * text.
 *
 * @param {String} searchText
 * @param {Array<Option>} objectsToSearch
 * @param {Array<String>} propertiesToSearch
 */
export function setSearchResultProperties(searchText, objectsToSearch, propertiesToSearch) {
    // Build empty search results object
    let searchResults = {textFound: false};
    for (const propertyToSearch of propertiesToSearch) {
        for (const searchResultProperty of Object.values(SearchResultProperties)) {
            searchResults[`${propertyToSearch}${searchResultProperty}`] = '';
        }
    }

    // Fill in search results if applicable
    const regex = new RegExp(escapeRegExp(searchText), 'i');
    return objectsToSearch.map((object) => {
        let index = -1;
        object = {...object, ...searchResults};

        if (searchText) {
            for (const propertyToSearch of propertiesToSearch) {
                index = object[propertyToSearch] ? object[propertyToSearch].search(regex) : -1;
                if (index !== -1) {
                    object.textFound = true;
                    object[`${propertyToSearch}${SearchResultProperties.TEXT_FOUND_PREFIX}`] =
                        object[propertyToSearch].substring(0, index);
                    object[`${propertyToSearch}${SearchResultProperties.TEXT_FOUND}`] = object[
                        propertyToSearch
                    ].substring(index, index + searchText.length);
                    object[`${propertyToSearch}${SearchResultProperties.TEXT_FOUND_SUFFIX}`] =
                        object[propertyToSearch].substring(index + searchText.length);
                }
            }
        }
        return object;
    });
}
