import * as dayjs from 'dayjs';
import {DEFAULT_DATE_FORMAT} from '@/config';

export default function (value, {format = DEFAULT_DATE_FORMAT, isUTC = true} = {}) {
    if (!value) return;
    if (typeof value === 'string') {
        if (isUTC) value = value.endsWith('Z') ? new Date(value) : new Date(`${value}Z`);
        else value = value.replace('Z', '');
    }
    return dayjs(value).format(format);
}
