// Manages the user's doctors
// In modules/index.js this module is merged with the generic manage.js module
// which enables paginated queries.

const state = {};

const getters = {};

const mutations = {};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
