export const IgnorableFieldsWhenCopying = Object.freeze([
    'doctor',
    'preOpDataSetId',
    'lensCertificationSummary',
    'preOpData.OD.preOpDataId',
    'preOpData.OD.dataSourceId',
    'preOpData.OD.status',
    'preOpData.OD.calculatorResults',
    'preOpData.OD.lensModelCode',
    'preOpData.OD.asphericEdof',
    'preOpData.OS.preOpDataId',
    'preOpData.OS.dataSourceId',
    'preOpData.OS.status',
    'preOpData.OS.calculatorResults',
    'preOpData.OS.lensModelCode',
    'preOpData.OS.asphericEdof',
]);

export const WARNING_RANGES = 'zone-code';

export const DATA_SOURCES = {
    OCOS: 1,
};

export const WarningRanges = Object.freeze({
    ACD: {
        MIN: 3,
        MAX: 3.5,
    },
    CYLINDER: {
        MAX: 1.0,
        MIN: 0.75,
    },
    SPHERE: {
        MAX: -0.25,
        MIN: -3,
    },
});
