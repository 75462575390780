const _env = process.env;

export const HELP_ORIGIN =
    _env.NODE_ENV === 'development'
        ? 'http://localhost:8081/help'
        : `${window.location.origin}/help`;
export const CURRENCY_FORMAT = 'USD';
export const LOCALE_CURRENCY_FORMAT = 'en-US';
export const DEFAULT_DECIMAL_SEPATOR = '.';
export const DEFAULT_LANGUAGE_KEY = 'en';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const CALCULATOR_API = {
    BASE_URL: _env.VUE_APP_CALCULATOR_URL,
    TIMEOUT: 3000,
};
