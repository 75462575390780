import router from '@/router';
import date from '@/filters/date';
import * as dayjs from 'dayjs';

//
// STATE
//
const state = () => ({
    currentPatient: null,
    currentPatientCalculations: null,
    currentPatientLenses: [],
    currentPatientPreOpData: null,
    currentPatientSurgeryDateTimeData: null,
    currentPatientPrimaryLensOD: null,
    currentPatientPrimaryLensOS: null,
});

//
// GETTERS
//
const getters = {
    currentPatient: (state) => state.currentPatient,
    currentPatientCalculations: (state) => state.currentPatientCalculations,
    currentPatientLenses: (state) => state.currentPatientLenses,
    currentPatientPreOpData: (state) => state.currentPatientPreOpData,
    currentPatientSurgeryDateTimeData: (state) => state.currentPatientSurgeryDateTimeData,
    patientAge: (state) => {
        if (state.currentPatient === null) {
            return null;
        }
        var diff_ms = Date.now() - new Date(state.currentPatient.dateOfBirth).getTime();
        var age_dt = new Date(diff_ms);
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    },
    patientDob: (state) => {
        if (state.currentPatient === null) {
            return '0/0/0';
        }

        return date(state.currentPatient.dateOfBirth, {isUTC: false});
    },
};

//
// MUTATIONS
//
const mutations = {
    // Set the patient's profile info
    setPatientProfile(state, currentPatient) {
        if (currentPatient)
            currentPatient.dateOfBirth = currentPatient.dateOfBirth?.replace('Z', '');
        state.currentPatient = currentPatient;
    },

    // Set the calculations for the patient
    setPatientCalculations(state, calculations) {
        state.currentPatientCalculations = calculations;
    },

    // Set the patient's lenses info
    setPatientLenses(state, lenses) {
        state.currentPatientLenses = lenses;
    },

    // Set the patient's pre-op data
    setPatientPreOpDataAndCalc(state, preOpData) {
        state.currentPatientPreOpData = preOpData;
    },

    setPatientSurgeryDateTimeData(state, surgeryDateTimeData) {
        state.currentPatientSurgeryDateTimeData = surgeryDateTimeData;
    },
    // Set the patient's primary lens for a given eye
    setPatientPrimaryLens(state, {eye, primaryLens}) {
        state[`currentPatientPrimaryLens${eye.toUpperCase()}`] = primaryLens;
    },
    // Reset the patient data
    resetPatient(state) {
        state.currentPatient = null;
        state.currentPatientLenses = [];
        state.currentPatientSurgeryDateTimeData = null;
        state.currentPatientPreOpData = null;
    },
};

//
// ACTIONS
//
const actions = {
    // Fetch the patient info
    async fetchPatient({commit}, {patientId}) {
        let urlString = `patients/${patientId}`;
        try {
            let result = await this._vm.$http.get(urlString);
            let patient = result.data.results;
            if (result.data.success == false) {
                router.push({name: 'NotFound'});
            }
            commit('setPatientProfile', patient);
        } catch (err) {
            alert('Unable to fetch patient: ' + err);
        }
    },

    // Validate the patient's ID to make sure it's not a duplicate of an existing patient's
    async validatePatientId({commit}, {patientId, ocosPatientRef}) {
        let urlString = 'patients/validation/ocospatientref/';

        try {
            let result = (
                await this._vm.$http.post(urlString, {
                    patientId: patientId,
                    ocosPatientRef: ocosPatientRef,
                })
            ).data;
            return result;
        } catch (err) {
            alert('Unable to Validate Patient ID');
            console.log('Unable to Validate Patient ID: ');
            console.log(err);
        }

        console.log(commit);
    },

    // Save a new patient, and if successful, return the new patient's ID
    /* As per the backend specifications, the patientState consists of the following:
        - patientId
        - ocosPatientRef
        - firstName
        - lastName
        - dateOfBirth
        - doctorId
        - gender
        - patientNote
        - active
        - priority
    */
    async saveNewPatient({commit}, patientState) {
        let urlString = 'patients/v2';
        let result = 'result';

        // In the frontend code, the priority is stored as a number, but for the backend it needs to be a Boolean
        patientState.priority = patientState.priority === 0 ? false : true;
        patientState.dateOfBirth = dayjs(patientState.dateOfBirth).utc(true).format();

        try {
            result = await this._vm.$http.post(urlString, patientState);
        } catch (err) {
            throw new Error('Unable to Save patient New Patient');
        }
        commit('setPatientProfile', result.data.results);
        /* We need to get and return the ID for the new patient so that we can navigate to the patient details page for the patient. */
        let newPatientId = result.data.results.patientId;
        return newPatientId;
    },

    // Update an existing patient
    /* As per the backend specifications, the patientState consists of the following:
        - patientId
        - ocosPatientRef
        - firstName
        - lastName
        - dateOfBirth
        - doctorId
        - gender
        - patientNote
        - active
        - priority
    */
    async updatePatient({commit}, patientState) {
        /* The reason that the patientState is passed in as an object is because all of the properties will need to be passed to the backend, so it would be longer and more redundant to write out all the properties in the argument list and in the call to the backend.  */
        let urlString = `patients/${patientState.patientId}/v2`;
        // In the frontend code, the priority is stored as a number, but for the backend it needs to be a Boolean
        patientState.priority = patientState.priority === 0 ? false : true;

        try {
            await this._vm.$http.put(urlString, {
                ...patientState,
                dateOfBirth: dayjs(patientState.dateOfBirth).utc(true).format(),
            });
        } catch (err) {
            console.log('Unable to Save: ');
            console.log(err);
            throw new Error('Unable to Save patient');
        }
    },

    // Fetch patient's lenses data
    async fetchPatientLenses({commit}, {patientId}) {
        let urlString = `patients/${patientId}/lenses`;
        const lenses = (await this._vm.$http.get(urlString)).data.results;

        commit('setPatientLenses', lenses);
    },

    // Fetch the patient's pre-op data and calculations
    async fetchPatientPreOpDataAndCalc({commit}, {patientId}) {
        let urlString = `patients/${patientId}/preopcalcdata`;
        const preopcalcdata = (await this._vm.$http.get(urlString)).data.results;
        commit('setPatientPreOpDataAndCalc', preopcalcdata);
    },

    // Fetch the patient's surgery date/time data
    async fetchPatientSurgeryDateTimeData({commit}, {patientId}) {
        let urlString = `patients/${patientId}/surgerydatetime`;
        const surgerydatatime = (await this._vm.$http.get(urlString)).data.results;

        commit('setPatientSurgeryDateTimeData', surgerydatatime);
    },

    // Update the patient's surgery date/time data
    async updatePatientSurgeryDateTimeData(_, {patientId, dateTimeObj}) {
        let urlString = `patients/${patientId}/surgerydatetime`;
        return await this._vm.$http.post(urlString, {patientId, ...dateTimeObj});
    },

    // Update the patient's lens serial data
    async updatePatientLensSerialData(_, {patientId, opEye, lensSerial}) {
        let urlString = `patients/${patientId}/lensserial`;
        return await this._vm.$http.post(urlString, {opEye, lensSerial});
    },

    // Fetch the patient's primary lens for the given eye
    async fetchPrimaryLens({commit}, {patientId, eye}) {
        let urlString = `patients/${patientId}/primarylens/${eye}`;
        const response = await this._vm.$http.get(urlString);

        const primaryLens = response.data.results;
        commit('setPatientPrimaryLens', {eye, primaryLens});

        return response.data;
    },
};

//
// DEFAULT EXPORT
//
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
